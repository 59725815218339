import T from "prop-types";
import { useRouter } from "next/router";
import { useEffect } from "react";
import Script from "next/script";
import Link from "components/Link";
import { Button, Container, Grid, useMediaQuery } from "components/MUI";
import { HUBSPOT_PORTAL_ID, HUBSPOT_FORM_ID } from "config/system";

import theme from "config/theme";
import { Small, H2, Body } from "components/atoms/Typography";
import CustomIcon from "components/atoms/CustomIcon";

import { StyledBox, StyledContainer } from "./ContactUs.styles";

function HubspotForm() {
  useEffect(() => {
    const loadHubspotForm = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: HUBSPOT_PORTAL_ID,
          formId: HUBSPOT_FORM_ID,
          target: "#form-container",
        });
      }
    };

    const formContainer = document.getElementById("form-container");
    if (formContainer) {
      formContainer.innerHTML = "";
    }

    loadHubspotForm();

    return () => {
      if (formContainer) {
        formContainer.innerHTML = "";
      }
    };
  }, []);

  return (
    <Grid item md={12} xs={12}>
      <div id="form-container" />
      <Script
        src="https://js.hsforms.net/forms/embed/v2.js"
        strategy="lazyOnload"
        onLoad={() => {
          if (window.hbspt) {
            window.hbspt.forms.create({
              portalId: HUBSPOT_PORTAL_ID,
              formId: HUBSPOT_FORM_ID,
              target: "#form-container",
            });
          }
        }}
      />
    </Grid>
  );
}

function ContactUs({
  variant,
  anchor,
  contactUs,
  contactBtn,
  emailBtn,
  phoneBtn,
}) {
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const { locale } = useRouter();
  const showHsForm = locale === "en";

  return (
    <StyledContainer
      id={anchor}
      maxWidth={false}
      component="section"
      variant={variant}
    >
      <Container
        maxWidth="xl"
        sx={{
          py: theme.spacing(10),
        }}
      >
        <Grid
          container
          spacing={{ xs: theme.spacing(5), md: theme.spacing(4) }}
        >
          <Grid item md={4} xs={12}>
            <H2
              sx={{ mb: contactUs?.text ? theme.spacing(3) : theme.spacing(5) }}
              align={isSmall ? "center" : "left"}
            >
              {contactUs.title}
            </H2>
            {contactUs?.text && (
              <Body
                as="p"
                align={isSmall ? "center" : "left"}
                sx={{
                  mb: contactBtn ? theme.spacing(2) : 0,
                }}
              >
                {contactUs.text}
              </Body>
            )}
            {contactBtn?.linkText && (
              <Link
                type="button"
                variant="contained"
                color="secondary"
                size="large"
                disableElevation
                fullWidth
                href={contactBtn.link}
              >
                {contactBtn.linkText}
              </Link>
            )}
          </Grid>

          <Grid item md={8} xs={12} container spacing={4}>
            {showHsForm && <HubspotForm />}
            <Grid item md={6} xs={12}>
              <StyledBox variant={variant}>
                <CustomIcon
                  name="envelope"
                  fill={
                    variant === "dark"
                      ? theme.palette.primary.contrastText
                      : theme.palette.primary.main
                  }
                  sx={{ fontSize: "2.5rem" }}
                />
                <Small
                  as="p"
                  color={
                    variant === "dark"
                      ? theme.palette.primary.contrastText
                      : theme.palette.primary.main
                  }
                  textAlign="center"
                  sx={{ lineHeight: "1.313rem", py: theme.spacing(2) }}
                >
                  {emailBtn.text}
                </Small>
                <Button
                  component="a"
                  variant="outlined"
                  color={variant === "dark" ? "secondary" : "primary"}
                  size="large"
                  disableElevation
                  fullWidth
                  href={`mailto:${emailBtn.email}`}
                >
                  {emailBtn.linkText}
                </Button>
              </StyledBox>
            </Grid>
            <Grid item md={6} xs={12}>
              <StyledBox variant={variant}>
                <CustomIcon
                  name="phone"
                  fill={
                    variant === "dark"
                      ? theme.palette.primary.contrastText
                      : theme.palette.primary.main
                  }
                  sx={{ fontSize: "2.5rem" }}
                />
                <Small
                  as="p"
                  color={
                    variant === "dark"
                      ? theme.palette.primary.contrastText
                      : theme.palette.primary.main
                  }
                  textAlign="center"
                  sx={{ lineHeight: "1.313rem", py: theme.spacing(2) }}
                >
                  {phoneBtn.text}
                </Small>
                <Button
                  component="a"
                  variant="outlined"
                  color={variant === "dark" ? "secondary" : "primary"}
                  size="large"
                  disableElevation
                  fullWidth
                  href={`tel:${phoneBtn.number}`}
                >
                  {phoneBtn.linkText}
                </Button>
              </StyledBox>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </StyledContainer>
  );
}

ContactUs.propTypes = {
  variant: T.oneOf(["light", "dark"]),
  anchor: T.string.isRequired,
  contactUs: T.shape({ title: T.string, text: T.string }).isRequired,
  contactBtn: T.shape({ link: T.string, linkText: T.string }),
  emailBtn: T.shape({ text: T.string, email: T.string, linkText: T.string })
    .isRequired,
  phoneBtn: T.shape({ text: T.string, number: T.string, linkText: T.string })
    .isRequired,
};

ContactUs.defaultProps = {
  variant: "dark",
  contactBtn: null,
};

export default ContactUs;
